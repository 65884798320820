<template>
    <div class="select-contianer">
        <div class="select-timelimit">
            <!-- <el-dropdown trigger="click" @command="handleCommand">
                <span class="dropdown-title">
                    <font-icon class="talent-icon talent-icon-calendar" href="#icon-calendar"></font-icon>{{timeLimit}}<i
                    class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown" class="timelimit-dropdown-menu">
                    <el-dropdown-item v-for="item in timeLimits" :key="item.name"
                                      :class="{'bubble-date-selected':timeLimit==item.name}" :command="item">
                        {{item.name}}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown> -->
            <date-range-picker 
                size="sm"
                @date-picked="handleDatePicked"
            />
        </div>

        <div class="bubble-margin-top">
          <template v-for="item in bubbleParam.state">
            <Bubble
                :count="item.count"
                group="Label"
                :isSelect="item.isSelect"
                :hashCode="item.hashCode"
                :disable="item.count <= 0"
                @click="handleBubbleClick"
                :enableTooltip="item.enableTooltip"
                type="primary"
                :key="item.code"
                :adaptiveSize="true"
                v-if="tableType == item.code || item.code > 2"
            >
                {{ item.label }}
                <span v-if="item.enableTooltip" slot="tooltipContent">
                    {{ item.toolTip}}
                </span>
            </Bubble>
          </template>
            <Bubble
                v-for="item in bubbleParam.recommend"
                :count="item.count"
                group="Label"
                :isSelect="item.isSelect"
                :hashCode="item.hashCode"
                :disable="item.count <= 0"
                @click="handleBubbleClick"
                :enableTooltip="item.enableTooltip"
                type="red"
                :key="item.code"
                :adaptiveSize="true"
            >
                {{ item.label }}
                <span v-if="item.enableTooltip" slot="tooltipContent">
                    {{ item.toolTip}}
                </span>
            </Bubble>
            <Bubble
                v-for="item in bubbleParam.job"
                :count="item.count"
                group="Label"
                :isSelect="item.isSelect"
                :hashCode="item.hashCode"
                :disable="item.count <= 0"
                @click="handleBubbleClick"
                :enableTooltip="item.enableTooltip"
                type="orange"
                :key="item.code"
                :adaptiveSize="true"
            >
                {{ item.label }}
                <span v-if="item.enableTooltip" slot="tooltipContent">
                    {{ item.toolTip}}
                </span>
            </Bubble>
        </div>
    </div>
</template>

<script>
    import Bubble from '#/component/common/bubble.vue'
    import {myOrders} from '#/js/config/selectBubble.json';
    import DateRangePicker from '#/component/common/date-range-picker.vue';
    import moment from 'moment';
    const TODAY = moment().format('YYYY-MM-DD');
    const lastMonth = moment().subtract(1, 'months').format("YYYY-MM-DD");
    export default {
        name: "selectWrapper",
        componentName: "selectWrapper",
        components: {
            Bubble,
            DateRangePicker,
        },
        props: {
            tableType: Number
        },
        data() {
            return {
                timeLimit: '最近一周',
                startDate: '',
                endDate: '',
                timeLimits: [
                    {name: '全部', value: 0},
                    {name: '最近一周', value: 7},
                    {name: '最近一个月', value: 30},
                    {name: '最近三个月', value: 90},
                    {name: '最近半年', value: 180},
                    {name: '半年以上', value: 181}
                ],
                bubbleParam: myOrders,
                bubbleParamHashMap: {},
                bubbleResult: {
                    Label: ''
                }
            }
        },
        mounted (){
            // this.handleCommand(this.timeLimits[1], null, true);
            this.handleDatePicked([lastMonth, TODAY]);
            this.bubbleParamHashMap = this.createHashMap(this.bubbleParam.job.concat(this.bubbleParam.recommend).concat(this.bubbleParam.state));
            for(let key in this.bubbleParamHashMap) {
                this.bubbleParamHashMap[key].isSelect = false;
            }
        },
        methods : {
            init(params) {
                Object.keys(params).map(item => {
                    if(this.bubbleParamHashMap[item]){
                        this.bubbleParamHashMap[item].count = params[item];
                    }
                })
            },
            clearResult() {
                this.bubbleResult = {
                    Label: ''
                };
                for (let key in this.bubbleParamHashMap) {
                    this.bubbleParamHashMap[key].isSelect = false;
                }
            },
            createHashMap(arr) {
                let bubbleMap = {};
                for (let key in arr) {
                    let orderKey = `${arr[key].hashCode}`;
                    bubbleMap[orderKey] = arr[key];
                }
                return bubbleMap;
            },
            handleCommand(item, el, isInit){
                this.timeLimit = item.name;
                if(!isInit) {
                    _tracker.track('MyOrdersTimeRangeSelect', JSON.stringify({
                        text: item.name,
                        tableName: window._pageCode ? window._pageCode.replace('MyOrders', '') : ''
                    }))
                }

                let today = new Date();
                let endDate ='',startDate='';
                switch(item.value){
                    case 0:
                        endDate = moment(today).format('YYYY-MM-DD');
                        startDate = '1970-01-01';
                        break;
                    case 181:
                        endDate = new Date(today.setTime(today.getTime() - (item.value-1) * 24 * 60 * 60 * 1000));
                        endDate = moment(endDate).format('YYYY-MM-DD');
                        startDate = "1970-01-01";
                        break;
                    default:
                        endDate = moment(today).format('YYYY-MM-DD');
                        startDate = new Date(today.setTime(today.getTime() - (item.value-1) * 24 * 60 * 60 * 1000));
                        startDate = moment(startDate).format('YYYY-MM-DD');
                }
                this.startDate = startDate;
                this.endDate = endDate;
                this.$emit('timeLimitChange',{startDate:this.startDate,endDate: endDate,name:item.name});
            },
            handleDatePicked(dates) {
                this.$emit('timeLimitChange', {
                    startDate: dates[0],
                    endDate: dates[1] 
                    });
            },

            handleBubbleClick(group, hashCode, isSelect) {
                let eventName = 'MyOrdersBubbleClick';
                for (let key in this.bubbleParamHashMap) {
                    if (key !== hashCode) {
                        this.bubbleParamHashMap[key].isSelect = false;
                    } else {
                        this.bubbleParamHashMap[key].isSelect = isSelect;
                    }
                }
                if (isSelect) {
                    this.bubbleResult[group] = this.bubbleParamHashMap[hashCode].hashCode;
                    _tracker.track(eventName, JSON.stringify({
                        filterType: this.bubbleParamHashMap[hashCode].code,
                        filterText: this.bubbleParamHashMap[hashCode].label
                    }))
                    return this.$emit('bubbleClick', {
                        text: this.bubbleParamHashMap[hashCode].label,
                        code: this.bubbleParamHashMap[hashCode].code
                    }, true);
                } else {
                    _tracker.track(eventName, JSON.stringify({
                        filterType: '',
                        filterText: ''
                    }))
                    return this.$emit('bubbleClear');
                }
            }
        }
    }
</script>

<style lang="scss" scope>
    .select-contianer{
        position: relative;
        margin: 10px 20px 0 20px;
        padding: 10px 20px;
        background-color: #fff;
        border-radius: 4px
    }

    .select-timelimit{
        // position: absolute;
        display: inline-block;
        .el-dropdown{
            cursor: pointer;
            font-size: 14px;
            color: #666666;
            background: #FFFFFF;
            border: 1px solid #38BC9D;
            border-radius: 4px;
            .dropdown-title{
                outline: none;
                width: 125px;
                height: 26px;
                line-height: 26px;
                padding: 0px 25px 0 10px;
                display: inline-block;
                text-align: right;
                .talent-icon-calendar{
                    width: 16px;
                    height: 16px;
                    position: absolute;
                    left: 10px;
                    top: 45%;
                    transform: translateY(-50%);
                }
                .el-icon--right{
                    position: absolute;
                    right: 10px;
                    top: 6px;
                }
            }
        }
    }

    .timelimit-dropdown-menu{
        padding: 0;
        user-select: none;
        margin-top: 5px;
        text-align: center;
        .el-dropdown-menu__item.bubble-date-selected{
            background-color: rgba(56,188,157,0.1);
            color: $primary;
        }
        .popper__arrow {
            display: none;
        }
    }

    .bubble-margin-top{
        // padding-left: 135px;
        padding-left: 10px;
        display: inline-block;
        vertical-align: top;
    }
    @media screen and (max-width: 1366px){
        .select-contianer{
            display: block;
            .select-timelimit{
                position: absolute;
                .el-dropdown{
                    .dropdown-title{
                        font-size: 12px;
                        width: 105px;
                        height: 26px;
                        line-height: 26px;
                        padding-right: 10px;
                        .icon-calendar{
                            font-size: 12px;
                            margin-right: 4px;
                        }
                        .el-icon--right{
                            display: none;
                        }
                    }
                }
            }
            .bubble-margin-top{
                padding-left: 115px;
                .bubble-item{
                    font-size: 12px;
                    height: 28px;
                    line-height: 28px;
                    margin-left: 10px;
                    padding: 0px 10px;
                }
            }
        }
        .timelimit-dropdown-menu.select-contianer{
            width: 105px;
            .el-dropdown-menu__item{
                padding: 0 10px;
            }
        }
    }
</style>
