<template>
    <RecommendAuditTable
        ref="recommendAuditTable"
     />
</template>

<script>

import RecommendAuditTable from './layout/recommendAuditTable.vue';
import tableScrollTo from '#/js/mixins/tableScrollTo.js';

export default {
    name: "FloatingListRecommendAudit",
    components: {
        RecommendAuditTable,
    },
    data(){
        return{
        }
    },
    mixins: [tableScrollTo],
    activated() {
        this.tableScrollTo();
        // this.$refs.recommendAuditTable.resize();
        let _this = this;
        window.onresize = function () {
            // _this.$refs.recommendAuditTable.resize();
        }
    },
}
</script>

<style lang="scss" scope>

</style>