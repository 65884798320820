var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "search-label" }, [
    _c("div", { staticClass: "float-left" }, [
      _c("span", [
        _vm._v("共 "),
        _c("span", { staticClass: "color-orange" }, [
          _vm._v(_vm._s(_vm.count)),
        ]),
        _vm._v(" 个职位"),
      ]),
    ]),
    _c("div", { staticClass: "float-center" }, [
      _c(
        "div",
        { staticClass: "select-tag-list" },
        _vm._l(_vm.orderLabelShowList, function (item, tag) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.orderLabelShowList.length > 0,
                  expression: "orderLabelShowList.length > 0",
                },
              ],
              key: tag,
              staticClass: "select-tag",
            },
            [
              _c(
                "el-tooltip",
                {
                  attrs: {
                    placement: "top-start",
                    effect: "light",
                    "popper-class": "tooltip-label",
                  },
                },
                [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _vm._v(_vm._s(_vm._f("labelsTip")(_vm.labelList[item]))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "text-ellipsis" },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.searchParamTitle[item]
                              ? `${_vm.searchParamTitle[item]}：`
                              : ""
                          ) + " "
                        ),
                      ]),
                      _vm._l(_vm.labelList[item], function (param, index) {
                        return _c(
                          "span",
                          { key: index, staticClass: "content" },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(param.text) +
                                "\n                            "
                            ),
                            _vm.labelList[item].length > 0 &&
                            index !== _vm.labelList[item].length - 1
                              ? _c("span", [_vm._v(" , ")])
                              : _vm._e(),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]
              ),
              _c("span", {
                staticClass: "btn-close",
                on: {
                  click: function ($event) {
                    return _vm.handleCleanLabel(item)
                  },
                },
              }),
            ],
            1
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }