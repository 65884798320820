<template>
<div class="work-table-container">
    <div class="my-orders-wrapper">
        <div class="top-wrap">
            <date-range-picker 
                size="sm"
                @date-picked="handleDatePicked"
            />
        </div>

        <selectLabel
            ref="searchLabel"
            :count="page.total"
            :labelList="labelList"
            :orderLabelShowList="orderLabelShowList"
            @handleCleanLabel="handleSelectCleanLabel"
        ></selectLabel>

        <div class="my-orders-table">

            <!-- :row-class-name="rowClassName"
            @selection-change="handleSelectionChange"
                @expand-change="handleExpandChange" -->
            <el-table
                border
                height="700"
                style="width:100%"
                ref="multipleTable"
                tooltip-effect="light"
                :data="tableData"
                v-loading="tableDataLoading"
                >
                
                <el-table-column
                    fixed
                    width="110"
                    label="推荐时间"
                    prop="created"
                    class-name="time-sort-cell">
                    
                    <template slot-scope="scope">
                        <span>
                            {{ scope.row.created | formatDate }}
                        </span>
                    </template>
                </el-table-column>
                
                <el-table-column
                    label="姓名"
                    :show-overflow-tooltip="true"
                    width="100"
                    fixed
                    class-name="name-cell"
                    >
                    <template #header>
                        <select-popover
                            :enableSearch="true"
                            :enableCount="false"
                            type="SearchOnly"
                            :selectItem="[]"
                            label="realName"
                            title="姓名"
                            :maxSelect="5"
                            @selectChange="selectDataChange"
                        ></select-popover>
                    </template>
                    <template slot-scope="{row}">
                        <!-- <a 
                            class="candidate-link" 
                            target="_blank"
                            :href="'/Headhunting/MyCompany.html#/candidateDetail/' + row.candidateId + '/recommendation/' + row.id">
                            {{row.candidateInfo.realName}}
                        </a> -->
                        <span>{{row.candidateInfo.realName}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                    v-for="(item, index) in titleStore.slice(2, titleStore.length)"
                    :key="item + index"
                    :label="tableTitleMap[item].label"
                    :label-class-name="tableTitleMap[item].labelClassName"
                    :min-width="tableTitleMap[item].minWidth"
                    :show-overflow-tooltip="tableTitleMap[item].showOverflowTooltip">
                    <template slot="header">
                        <template v-if="tableTitleMap[item].isHeaderSelect">
                            
                            <template v-if="item == 'yearOfExperience'">
                                <select-popover
                                    :enableSearch="true"
                                    :enableCount="false"
                                    type="SearchOnly"
                                    :selectItem="[]"
                                    label="yearOfExperience"
                                    title="工作年限"
                                    :maxSelect="5"
                                    @selectChange="selectDataChange"
                                ></select-popover>
                            </template>
                            <template v-else>
                                <select-popover
                                    :maxSelect="5"
                                    :enableCount="false"
                                    :title="tableTitleMap[item].label"
                                    :label="tableTitleMap[item].name"
                                    :enableSearch="tableTitleMap[item].headerSelectEnableSearch"
                                    :selectItem="headerFilter[tableTitleMap[item].name + 'Items']"
                                    @selectChange="selectDataChange"
                                ></select-popover>
                            </template>
                            
                        </template>
                        <template v-else>
                            <div class="table-slot">
                                {{tableTitleMap[item].label}}
                            </div>
                        </template>
                    </template>
                    
                    <template slot-scope="scope">

                        <template v-if="item == 'location'">
                            {{scope.row.candidateInfo.locationText}}
                        </template>

                        <template v-if="item == 'school'">
                            {{scope.row.candidateInfo.school}}
                        </template>

                        <template v-if="item == 'degree'">
                            {{scope.row.candidateInfo.degree | filterDegree}}
                        </template>

                        <template v-if="item == 'title'">
                            {{scope.row.candidateInfo.title}}
                        </template>
                        
                        <template v-if="item == 'company'">
                            {{scope.row.candidateInfo.company}}
                        </template>

                        <template v-if="item == 'recommendTitle'">
                            {{scope.row.jobInfo.name}}
                        </template>

                        <template v-if="item == 'recommendCustomer'">
                            {{scope.row.jobInfo.definedCustomerName || scope.row.jobInfo.firmName}}
                        </template>

                        <template v-if="item == 'recommendToHR'">
                            <!-- {{scope.row.jobOwnerName}} -->
                            <div class="recommend-avatar">
                                <avatar
                                    :userId="scope.row.recruiter.userId"
                                    :enableLink="false"
                                    :src="scope.row.recruiter.avatarUrl | avatarUrlFilter"
                                    size="sm"
                                    :shareData="getShareData(scope.row)"
                                ></avatar>
                            </div>
                        </template>
                        
                        <template v-if="item == 'yearOfExperience'">
                            <div class="table-slot table-slot-content">
                                {{ scope.row.candidateInfo.yearOfExperience + '年'}}
                            </div>
                        </template>

                        <!-- 
                        <template v-if="item == 'recommender'">
                            <div class="center recommend-avatar">
                                <avatar
                                    :userId="scope.row.ownerId"
                                    :enableLink="false"
                                    :src="scope.row.recommenderAvatarUrl | avatarUrlFilter"
                                    size="sm"
                                    :shareData="getShareData(scope.row)"
                                ></avatar>
                            </div>
                        </template> -->

                        <template v-if="item == 'lastestStatus'">
                            <span>
                                {{scope.row.status | formatStatus(scope.row)}}
                                <template v-if="scope.row.status == 2">
                                    <el-popover
                                        placement="bottom"
                                        title="拒绝原因:"
                                        width="200"
                                        trigger="hover"
                                        :content="scope.row.remark">
                                        <i slot="reference" 
                                            class="el-icon-warning-outline" 
                                            style="color:red;">
                                        </i>
                                    </el-popover>
                                </template>
                            </span>
                        </template>

                    </template>
                </el-table-column>

                <template slot="empty">
                    <div>
                        <span class="empty-img"></span>
                        <p class="empty-data">暂无数据</p>
                    </div>
                </template>
            </el-table>


            <table-page-tab
                :filterIndex="5"
                :pageTabs="pageTabJson"
            ></table-page-tab>

            <!-- footer -->
            <div class="work-table-footer">
                <div class="footer-left">
                </div>
                <el-pagination
                    class="el-pagination-workTable"
                    :current-page="page.current"
                    :page-sizes="[20, 30, 50]"
                    :page-size="page.size"
                    layout="total, sizes, prev, pager, next, slot"
                    :total="page.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange">
                    <span class="pagination-text">
                        <span>前往<el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页</span>
                        <span @click="handlePagerJump">跳转</span>
                    </span>
                </el-pagination>
            </div>

        </div>
        
    </div>
</div>
</template>

<script>
import moment from 'moment';
import emitter from '#/js/mixins/emitter.js';
import floatingListService from '#/js/service/floatingListService.js';

import { RecommendAuditTable as tableTitleMap } from '#/js/config/tableTitleMap.json';

import SelectWrapper from '../component/selectWrapper.vue';
import SelectLabel from '../component/selectLabel.vue';
import DateRangePicker from '#/component/common/date-range-picker.vue';
import TablePageTab from '#/component/workTable/table-page-tab.vue';
import Avatar from '#/component/common/avatar.vue';
import selectPopover from '#/component/common/select-popover.vue';



const DegreeMap = {
    "0": "不要求",
    "1": "高中及以下",
    "2": "大专",
    "3": "本科",
    "4": "硕士",
    "5": "MBA",
    "6": "博士"
};
const RecommendApplicationStatus = {
    "0": "未审核",
    "1": "通过",
    "2": "拒绝",
};
const TODAY = moment().format('YYYY-MM-DD');
const lastMonth = moment().subtract(1, 'months').format("YYYY-MM-DD");


export default {
    name: "myA2aAduitWrapper",
    componentName: "myA2aAduitWrapper",
    mixins: [emitter],
    components: {
        SelectWrapper,
        SelectLabel,
        DateRangePicker,
        TablePageTab,
        Avatar,
        selectPopover,
    },
    props: {
        tableType: {
            type: Number,
            default() {
                return 2;
            }
        },
    },
    data(){
        return{
            page: {
                current: 0,
                size: 20,
                total:0
            },
            pagerJump: 0,
            labelList: {
                realName: [],
                yearOfExperience: [],
                degree: [],
                status: [],
            },
            orderLabelShowList: [],
            tableData: [],
            tableDataLoading: false,
            tableTitleMap: tableTitleMap,

            dateRange: [lastMonth, TODAY],

            headerFilter: {
                degreeItems: [
                    {value: 0, label: '不要求', isSelect: false, count: 0,},
                    {value: 1, label: '高中及以下', isSelect: false, count: 0,},
                    {value: 2, label: '大专', isSelect: false, count: 0,},
                    {value: 3, label: '本科', isSelect: false, count: 0,},
                    {value: 4, label: '硕士', isSelect: false, count: 0,},
                    {value: 5, label: 'MBA', isSelect: false, count: 0,},
                    {value: 6, label: '博士', isSelect: false, count: 0,},
                ],
                statusItems: [
                    {value: 0, label: '未审核', isSelect: false, count: 0,},
                    // {value: 1, label: '审核通过', isSelect: false, count: 0,},
                    {value: 2, label: '审核拒绝', isSelect: false, count: 0,},
                ],
            },
            titleStore: ['created', 'realName', 'location', 'school', 'degree', 'yearOfExperience', 'title', 'company', 'recommendTitle', 'recommendCustomer', 'recommendToHR', 'lastestStatus', ],

            realName: '',
            yearOfExperience: '',
            degreeArray: [],
            statusArray: [],
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        // 是PM还是财务人员
        isFinancial () {
            return this.userInfo.privilegeCodeList && this.userInfo.privilegeCodeList.includes('Financial');
        },
        isOfferList () {
            return this.userInfo.privilegeCodeList && this.userInfo.privilegeCodeList.includes('OfferList');
        },
        pageTabJson() {
            let pageTabs = [];
            if (this.isFinancial) {
                pageTabs = [
                    {
                        enTitle: "Floating List",
                        cnTitle: "流程管理",
                        path: "/floatingList/unApply",
                        title: "未申请的开票",
                        tabIndex: 2,
                        aliveComponent: "FloatingListUnApplyList",
                    },
                    {
                        enTitle: "Floating List",
                        cnTitle: "流程管理",
                        path: "/floatingList/apply",
                        title: "开票申请",
                        tabIndex: 3,
                        aliveComponent: "FloatingListApplyList",
                    },
                ]; 
            } else {
                pageTabs = [
                    {
                        enTitle: "Floating List",
                        cnTitle: "流程管理",
                        path: "/floatingList",
                        title: "收到的推荐",
                        tabIndex: 0,
                        aliveComponent: "FloatingListRecommendToMe",
                    },
                    {
                        enTitle: "Floating List",
                        cnTitle: "流程管理",
                        path: "/floatingList/myRecommend",
                        title: "我的推荐",
                        tabIndex: 1,
                        aliveComponent: "FloatingListMyRecommend",
                    },
                    // {
                    //     enTitle: "Floating List",
                    //     cnTitle: "流程管理",
                    //     path: "/floatingList/unApply",
                    //     title: "未申请的开票",
                    //     tabIndex: 2,
                    //     aliveComponent: "FloatingListUnApplyList",
                    // },
                    {
                        enTitle: "Floating List",
                        cnTitle: "流程管理",
                        path: "/floatingList/apply",
                        title: "开票申请",
                        tabIndex: 3,
                        aliveComponent: "FloatingListApplyList",
                    },
                    {
                        enTitle: "Floating List",
                        cnTitle: "流程管理",
                        path: "/floatingList/recommendAudit",
                        title: "HR直招推荐审批",
                        tabIndex: 5,
                        aliveComponent: "FloatingListRecommendAudit",
                    },
                ];
            }

            if(this.isOfferList) {
                const tabOjb = {
                        enTitle: "Floating List",
                        cnTitle: "流程管理",
                        path: "/floatingList/offerList",
                        title: "Offer列表",
                        tabIndex: 4,
                        aliveComponent: "FloatingListOfferList",
                    };
                pageTabs.push(tabOjb);
            }
            return pageTabs;
        },
    },
    watch: {
        
    },
    
    created() {
        this.getAuditList();
    },
    mounted() {
        
    },
    activated(){
        
    },
    filters: {
        formatDate(string) {
            return moment(string).format('YYYY-MM-DD');
        },
        formatStatus(status, row) {
            return RecommendApplicationStatus[String(status)];
        },
        avatarUrlFilter(url) {
            if(url) {
                url = url.replace(/^https?:/, location.protocol);
            }
            return url;
        },
        filterDegree(val) {
            return DegreeMap[String(val)]
        },
    },
    methods: {
        handleCleanLabel() {

        },
        handleDatePicked(dates) {
            if(dates.length == 2) {
                this.dateRange = dates;
                
                this.getAuditList();
            }
        },

        handleSizeChange(val) {
            this.page.size = val;
            this.page.current = 0;
            this.getAuditList();
        },
        handleCurrentChange(val) {
            this.page.current = val;
            this.getAuditList();
        },
        handlePagerJump(){
            let currentPager = Number(this.pagerJump),
            currentPageTotal = Math.ceil(this.page.total/this.page.size);
            if(currentPager > 0 && currentPager <= currentPageTotal){
                this.handleCurrentChange(currentPager)
            }
        },

        getAuditList() {
            const params = {
                startDate: this.dateRange[0],
                endDate: this.dateRange[1],
                
                start: this.page.current*this.page.size,
                take: this.page.size,

                resumeId: '', // 可不要
                candidateName: this.realName,
                yearOfExperience: this.yearOfExperience,
                degree: this.degreeArray,
                status: this.statusArray,
            };

            this.tableDataLoading = true;
            floatingListService
            .recommendationApplicationHRApplyList(params)
            .then(res => {
                this.page.total = res.total;
                this.tableData = res.list || [];
            })
            .finally(() => {
                this.tableDataLoading = false;
            });
        },
        contactPublisher() {

        },
        getShareData() {
            return {};
        },
        selectDataChange(label, result) {
            console.log(arguments)

            // "realName"yearOfExperience   degree  status
            if(result.length > 0){
                if(label == 'realName' || label == 'yearOfExperience') {
                    this.$data[label] = result[0].label || '';
                } else {
                    this.$data[label + 'Array'] = result.map(item => item.value);
                }
                // this.$emit('handleAddLabel', label, result, true);
                this.handleAddLabel(label, result);
            } else {
                if(label == 'realName' || label == 'yearOfExperience') {
                    this.$data[label] = '';
                } else {
                    this.$data[label + 'Array'] = [];
                }

                // this.$emit("handleCleanLabel", label);
                this.handleCleanLabel(label);
            }
            

            this.getAuditList();

        },

        handleAddLabel(label, result) {
            const idx = this.orderLabelShowList.findIndex(item => item == label);
            if(idx < 0) {
                this.orderLabelShowList.push(label);
            }
            this.labelList[label] = result.map(item => ({
                ...item,
                text: item.label,
            }));
        },

        handleCleanLabel(label) {
            const _idx = this.orderLabelShowList.findIndex(item => item == label);
            if(_idx >=0) {
                this.orderLabelShowList.splice(_idx, 1);
            }
            this.orderLabelShowList.splice()
            if (this.labelList[label].length <= 0) {
                return false;
            }
            this.labelList[label] = [];
        },

        handleSelectCleanLabel(label) {
            const _idx = this.orderLabelShowList.findIndex(item => item == label);
            if(_idx >=0) {
                this.orderLabelShowList.splice(_idx, 1);
            }
            this.orderLabelShowList.splice()
            if (this.labelList[label].length <= 0) {
                return false;
            }
            this.labelList[label] = [];

            // 重置刷新数据
            if(label == 'realName'|| label == 'yearOfExperience') {
                this.$data[label] = '';
            } else {
                this.$data[label + 'Array'] = [];
                this.headerFilter[label + 'Items'].forEach(item => {
                    item.isSelect =false;
                });
            }
            
            this.getAuditList();
        },


    },
}
</script>

<style lang="scss" scope>
.work-table-container {
    height: 100%;
    overflow-y: auto;
    .my-orders-wrapper {
        position: relative;
        height: 100%;
        min-height: 500px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .top-wrap{
            margin: 10px 20px 0 20px;
            padding: 10px 20px;
            background-color: #fff;
            border-radius: 4px;
        }

    }
}



.my-orders-table {
    height: 60.6% !important;
    padding: 0 20px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    letter-spacing: 0px;
    background:url('~@src/assets/images/home/home_bg.png') bottom center no-repeat;
    .el-table {
            height: 60.6% !important;
            min-height: 244px;
            overflow-y: auto;

            .el-loading-mask {
                z-index: 900;
            }

            th {
                padding: 0;
                height: 42px;
                line-height: 42px;
                background-color: #E8E8E8;
                border-color: #CCCCCC;
                color: #666666;

                .el-checkbox {
                    padding: 0 10px;
                }

                .cell {
                    padding: 0;

                    .caret-wrapper {
                        display: none;
                    }

                    .el-dropdown {
                        float: left;
                        width: 100%;
                        padding: 0;
                        text-align: center;
                        &.time-sort-dropdown {
                            line-height: unset;
                            margin-top: -7px;
                        }
                    }

                    .el-checkbox {
                        display: none;
                    }
                }

                &.name-cell .table-slot {
                    text-indent: 0;
                }
            }

            td {
                padding: 0;
                height: 40px;
                line-height: 40px;

                .cell {
                    white-space: nowrap;

                    .el-checkbox__input.is-checked .el-checkbox__inner {
                        background-color: #fff;
                        border-color: $primary;

                        &::after {
                            border-color: $primary;
                        }
                    }
                }

                .table-avatar {
                    display: block;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                }

                &.btn-menu .cell {
                    padding: 0;
                }

                &.avatar-cell .table-slot {
                    text-indent: 0;
                }

                .recommend-avatar {
                    text-align: center;
                }
            }

            .el-table__row {

                .el-checkbox__inner {
                    border-color: #999;
                }

                &.lazy {

                    td {
                        color: #BBBBBB;

                        &.name-cell .cell .candidate-link {
                            color: #bbb;

                            &:hover {
                                color: $primary;
                            }
                        }

                        &.expand-cell {
                            .el-table__expand-icon {
                                color: #bbb;
                            }

                            .el-table__expand-icon--expanded {
                                color: $primary;
                            }
                        }

                    }

                    .el-checkbox__inner {
                        border-color: #BBBBBB;
                    }

                }
            }

            .table-slot {
                padding: 0;
                text-indent: 10px;
                line-height: unset;
            }

            .table-slot-content {
                text-indent: 0;
            }

            .is-center .table-slot {
                text-indent: 0;
            }

            .time-sort-cell .cell {
                line-height: 40px;
                padding-left: 5px;
            };

            .expand-cell {
                border-right: none;

                .el-table__expand-icon--expanded {
                    color: $primary;
                }

                .el-table__expand-icon > .el-icon {
                    z-index: 10;

                    &.el-icon-arrow-right {
                        font-weight: bold;
                    }
                }
            }

            .name-cell .cell {
                padding-left: 5px;

                .candidate-link {
                    color: #666;

                    .hr-tag {
                        display: inline-block;
                        width: 26px;
                        height: 16px;
                        line-height: 16px;
                        background: #F5A623;
                        border-radius: 4px;
                        font-size: 11px;
                        color: #FFFFFF;
                        text-align: center;
                    }

                    &:hover {
                        color: $primary;
                    }
                }
            }

            .bg-green-dark {
                background-color: #ebf8f5;
            }

            .bg-orange-dark {
                background-color: #ffe4ce;
            }

            .el-table__empty-block {
                .el-table__empty-text {
                    line-height: inherit;
                }

                .empty-img {
                    display: inline-block;
                    background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                    width: 240px;
                    height: 228px;
                }

                .empty-data {
                    font-size: 16px;
                    line-height: 21px;
                    margin-bottom: 0px;
                }
            }

            .el-table-column--selection {
                border-right: none;
            }

            .recommend-dynamic {
                position: relative;
                z-index: 5;
            }
        }
}
</style>